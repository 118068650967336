@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#__next {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

body {
  // slate-800
  background-color: #1e293b;
}

@layer utilities {
  .text-shadow {
    text-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  }
}
